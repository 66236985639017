/* allgemeine Infos und viewport-sx 320px */

.meinungen-wrapper {
  background-color: var(--color-grey-light);
}

.meinungen-wrapper h3 {
  text-align: center;
}

.meinungsbox {
  text-align: center;
  background-color: white;
  padding: var(--gutter);
  margin-top: var(--gutter);
}

.meinungsbox h4 {
  width: 100%;
  font-size: var(--font-size-h5);
  color: var(--color-grey-dark);
  margin-bottom: calc(var(--gutter)*0.9);
}

.meinungsbox img {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: calc(var(--gutter)*0.9);
}

.meinungsbox p {
  width: 100%;
  margin-bottom: 0;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .meinungen-inner-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
  }

  .meinungsbox {
    display: flex;
    flex-wrap: wrap;
    width: 49%;
    justify-content: center;
    align-items: flex-start;
    padding: var(--gutter-horizontal);
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .meinungsbox {
    margin-top: calc(var(--gutter)*2);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .meinungen-inner-wrapper {
    padding-left: calc(var(--gutter)*2);
    padding-right: calc(var(--gutter)*2);
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .meinungen-inner-wrapper {
    padding-left: calc(var(--gutter)*8);
    padding-right: calc(var(--gutter)*8);
  }
}
