/* allgemeine Infos und viewport-sx 320px */
.seo-text {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.seo-text img {
  width: 70%;
  height: auto;
}

.seo-text h2 {
  width: 30%;
  font-size: 4rem;
  color: var(--color-primary);
  align-self: flex-end;
  margin-bottom: 0;
}

.seo-text h3 {
  font-family:'Futura LT W01 Light';
  font-weight: normal;
}

.seo-text h3 .text-bold {
  font-family:'Futura LT W01 Bold';
  font-weight: normal;
}
/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .seo-text {
    padding-right: var(--gutter);
  }

  .seo-text h3 {
    font-size: var(--font-size-h1);
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .seo-text h3 {
    font-size: var(--font-size-h3);
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .seo-text h3 {
    font-size: var(--font-size-h2);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .seo-text img {
    width: 50%;
    height: auto;
  }

  .seo-text h3 {
    font-size: var(--font-size-h1);
  }
}
