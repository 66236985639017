/* allgemeine Infos und viewport-sx 320px */
.referenzbox {
  margin-top: calc(var(--gutter)*2);
}

.referenzbox:first-of-type {
  margin-top: 0;
}

.referenz-img {
  display: flex;
  width: 100%;
  height: 10rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: var(--gutter);
}

.referenz-img img {
  width: 100%;
  height: auto;
}

.referenzbox h3 {
  width: 100%;
  margin-bottom: calc(var(--gutter)*0.5);
}

.referenzbox p {
  width: 100%;
  margin-bottom: 0;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .referenzbox-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .referenzbox {
    width: 48%;
    margin-right: 4%;
  }

  .referenzbox:nth-of-type(even) {
    margin-right: 0;
  }

  .referenzbox:nth-of-type(2) {
    margin-top: 0;
  }

  .referenz-img {
    height: 8.5rem;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .referenz-img {
    height: 12rem;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .referenzbox {
    width: 32%;
    margin-right: 2%;
  }

  .referenzbox:nth-of-type(even) {
    margin-right: 2%;
  }

  .referenzbox:nth-of-type(3n) {
    margin-right: 0;
  }

  .referenzbox:nth-of-type(3) {
    margin-top: 0;
  }

  .referenz-img {
    height: 10.5rem;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .referenzbox {
    width: 22%;
    margin-right: 4%;
  }

  .referenzbox:nth-of-type(even) {
    margin-right: 4%;
  }

  .referenzbox:nth-of-type(3n) {
    margin-right: 4%
  }

  .referenzbox:nth-of-type(4n) {
    margin-right: 0;
  }

  .referenzbox:nth-of-type(4) {
    margin-top: 0;
  }

  .referenz-img {
    height: 8.5rem;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .referenz-img {
    height: 11.5rem;
  }
}
