/* allgemeine Infos und viewport-sx 320px */
header {
  position: fixed;
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white;
  padding: calc(var(--gutter)*0.8);
  border-bottom: 2px solid var(--color-primary);
  z-index: 50;
}

.placeholder {
  background-color: blue;
  width: 100%;
  height: 3.9rem;
}

.header-wrapper a {
  display: block;
  width: 10rem;
}

.header-wrapper img{
  width: 100%;
  height: 100%;
}

.header-wrapper h1,
.header-kontakt {
  display: none;
}


/* Nav */
.main-nav {
  background-color: var(--color-grey);
}

.hamburger-button {
  outline: none;
  border: medium none;
  color: white;
  width: 3rem;
  height: 3rem;
  background-color: var(--color-grey);
}

.hamburger-button:hover {
  background-color: var(--color-grey-dark);
}

.hamburger-button svg {
  width: var(--font-size-h3);
  height: var(--font-size-h3);
  display: block;
  margin: 0 auto;
}

.mobile-page-title {
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 0;
  padding-left: var(--gutter);
  font-size: var(--font-size-h5);
}

ul.main-navigation {
  list-style: outside none none;
  padding-left:0;
  margin-top: var(--gutter);
}

ul.main-navigation li {
  font-family:'Futura LT W01 Light';
  font-weight: normal;
  padding: calc(var(--gutter)* 0.5) var(--gutter);
}

ul.main-navigation li:hover,
ul.main-navigation li.active {
  background-color: var(--color-grey);
}

.dropdown {
  position: absolute;
  left: 0;
  background-color: var(--color-grey-dark);
  margin-bottom: 0;
  width: 100%;
  padding: var(--gutter-horizontal);
  list-style-type: none;
}

.dropdown-list-item {
  padding: 0 var(--gutter);
}

.dropdown > li:last-of-type {
  border-right: none;
}

.dropdown > li:first-of-type {
  padding-left: 0;
}

.dropdown-list-item ul {
  font-size: var(--font-size-small);
  list-style-type: disc;
}

.dropdown-link {
  display: block;
  color: var(--font-color);
  text-decoration: none;
}

.submenu-2 {
  margin-bottom: var(--gutter-horizontal);
}

.dropdown-link-layer-2 {
  color: var(--font-color);
  text-decoration: none;
}

.dropdown-link:hover,
.dropdown-link-layer-2:hover {
  color: var(--color-primary);
}

.main-navigation .open {
  color: var(--font-color);
}

.main-navigation .open + .dropdown {
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  header {
    align-items: center;
  }

  .header-wrapper {
    display: flex;
    width: 31rem;
    justify-content: flex-start;
    align-items: center;
  }

  .header-wrapper a {
    margin-right: var(--gutter);
  }

  .header-kontakt,
  .header-kontakt p {
    display: block;
  }

  .header-kontakt p {
    width: 100%;
    text-align: right;
    margin-bottom: calc(var(--gutter)*0.3);
  }

  .header-kontakt a {
    width: 100%;
    font-family:'Futura LT W01 Bold';
    font-weight: normal;
    font-size: var(--font-size-h6);
    color: var(--color-primary);
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  /* header */
  header {
    position: relative;
    display: inherit;
    padding-top: var(--gutter);
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 0;
  }

  .placeholder {
    display: none;
  }

  .header-wrapper {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: calc(var(--gutter)*0.8);
  }

  .header-wrapper h1 {
    display: block;
    width: 13rem;
    text-align: left;
    margin-bottom: 0;
    transform: rotate(-3deg);
  }

 .header-kontakt {
    width: 19rem;
  }

  .header-kontakt p {
    margin-bottom: calc(var(--gutter)*0.3);
  }

  .header-wrapper div p,
  .header-wrapper div a {
    width: 100%;
    text-align: right;
  }

  .header-wrapper div a {
    margin-right: 0;
  }

  /* Nav */
  .main-nav {
    width: 100%;
    background-color: var(--color-grey);
    border-bottom: 3px solid var(--color-grey-light);
    border-top: 3px solid var(--color-grey-light);
    margin-bottom: 0;
  }

  ul.main-navigation {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
    margin-top: 0;
  }

  ul.main-navigation li {
    display: inline-block;
    padding-left: calc(var(--gutter)* 0.9);
    padding-right: calc(var(--gutter)* 0.9);
  }

  ul.main-navigation li:hover,
  ul.main-navigation li.active {
    background-color: var(--color-primary);
  }

  ul.main-navigation li a {
    color: var(--font-color);
    font-weight: 400;
  }

  ul.main-navigation li:hover a,
  ul.main-navigation li.active a {
    color: white;
  }

  /* 2. Ebene */
  .dropdown {
    display: none;
    position: absolute;
    left: 0;
    top: 2.3rem;
    background-color: var(--color-primary);
    margin-bottom: 0;
    width: 14rem;
    padding: 0;
    list-style-type: none;
    z-index: 10;
    box-shadow:0px 4px 5px 0px rgba(0,0,0, 0.2);
  }

  ul.main-navigation li.active .dropdown-link,
  ul.main-navigation li .dropdown-link,
  ul.main-navigation li .dropdown-link-layer-2 {
    color: var(--font-color);
  }

  ul.dropdown li {
    display: block;
    width: 100%;
    padding: calc(var(--gutter)*0.5);
    margin-left: 0;
    background-color: var(--color-grey);
    border-right: 0;
  }

  ul.dropdown li:first-of-type,
  ul.dropdown li {
    padding-left: calc(var(--gutter)*0.9);
  }

  ul.dropdown li a {
    width: auto;
  }

  ul.dropdown li:hover  {
    background-color: var(--color-primary-light);
    border-bottom: 0;
  }

  ul.dropdown li:hover .dropdown-link,
  ul.dropdown li:hover .dropdown-link-layer-2 {
    color: white;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  /* header */
  .header-wrapper h1 {
    width: 26rem;
  }

  .header-kontakt a {
    font-size: var(--font-size-h4);
  }

  /* Nav */
  ul.main-navigation li {
    font-size: var(--font-size-h6);
  }

  /* 2. Ebene */
  .dropdown {
    width: 16rem;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .header-wrapper a {
    width: 12rem;
  }

  .header-wrapper h1 {
    width: 34rem;
    font-size: var(--font-size-h2);
  }

  .header-kontakt {
    width: 23rem;
  }

  .header-kontakt p {
    font-size: var(--font-size-h5);
  }

  /* Nav */
  ul.main-navigation li {
    font-size: var(--font-size-h5);
    padding: calc(var(--gutter)* 0.8) calc(var(--gutter)*1.5);
  }

  ul.dropdown li:first-of-type,
  ul.dropdown li {
    padding-left: calc(var(--gutter)*1.5);
  }

  /* 2. Ebene */
  .dropdown {
    width: 18rem;
    top: 2.9rem;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .header-wrapper a {
    width: 13rem;
  }

  .header-wrapper h1 {
    width: 50rem;
    font-size: var(--font-size-h1);
  }

  .header-kontakt p {
    margin-bottom: calc(var(--gutter)*0.5);
  }

  .header-kontakt a {
    font-size: var(--font-size-h3);
  }
}
