/*
 * Filename: forms.css
 *
 * Default Styles for Forms.
 */

label,
.pure-form label {
  display: block;
}

textarea,
.pure-form textarea {
  display: block;
}

.pure-form select {
  height: 2.5rem;
}

#contactBottem input,
#contactBottem select,
#contactBottem textarea,
#contactAnfrage input,
#contactAnfrage select,
#contactAnfrage textarea {
  margin-bottom: calc(var(--gutter)*0.5);
  box-shadow: none;
}
