/* allgemeine Infos und viewport-sx 320px */
.slider-wrapper {
  display: none;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .slider-wrapper {
    display: block;
  }

  .swiper-slide {
    position: relative;
  }
  .slider-top-content {
    width: 50%;
    position: absolute;
    left: 3%;
    top: 50%;
    transform: translateY(-50%);
  }

  .slider-top-content h2,
  .slider-top-content h3 {
    display: table;
    font-size: var(--font-size);
    color: white;
    margin-bottom: 0;
    padding: calc(var(--gutter)*0.3) calc(var(--gutter)*0.5);
  }

  .slider-top-content h2 {
    background-color: var(--font-color);
  }

  .slider-top-content h3 {
    background-color: var(--color-primary);
  }

  .slider-top-content p {
    color: var(--color-grey-dark);
    margin-top: calc(var(--gutter)*0.3);
    margin-bottom: calc(var(--gutter)*0.3);
  }

  .slider-top-content a {
    display: inline-block;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .slider-top-content h2,
  .slider-top-content h3 {
    font-size: var(--font-size-h5);
  }

  .slider-top-content p {
    margin-top: calc(var(--gutter)*0.5);
    margin-bottom: calc(var(--gutter)*1.2);
  }
}

/* Zusätzlicher Viewport 1152px */
@media screen and (min-width: 72rem) {
  .slider-top-content {
    width: 40%;
    left: 8%;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .slider-top-content {
    width: 45%;
    left: 3%;
  }

  .slider-top-content h2,
  .slider-top-content h3 {
    font-size: var(--font-size-h3);
  }

  .slider-top-content p {
    font-size: var(--font-size-h5);
    line-height: 1.2;
    margin-top: calc(var(--gutter)*0.9);
    margin-bottom: calc(var(--gutter)*1.8);
  }
}

/* Zusätzlicher Viewport 1376px */
@media screen and (min-width: 86rem) {
  .slider-top-content {
    width: 40%;
    left: 8%;
  }
}

/* Zusätzlicher Viewport 1480px */
@media screen and (min-width: 92.5rem) {
  .slider-top-content {
    width: 35%;
    left: 9%;
  }

  .slider-top-content h2,
  .slider-top-content h3 {
    font-size: var(--font-size-h2);
    padding: calc(var(--gutter)*0.3) calc(var(--gutter)*0.8);
  }

  .slider-top-content p {
    margin-bottom: calc(var(--gutter)*1.9);
  }
}

/* Zusätzlicher Viewport 1540px */
@media screen and (min-width: 96.25rem) {
  .slider-top-content {
    width: 30%;
    left: 11%;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .slider-top-content {
    width: 30%;
    left: 3%;
  }

  .slider-top-content h2,
  .slider-top-content h3 {
    font-size: var(--font-size-h1);
  }

  .slider-top-content p {
    font-size: var(--font-size-h4);
    line-height: 1.3;
    margin-bottom: calc(var(--gutter)*2.5);
  }
}

/* Zusätzlicher Viewport 1696 */
@media screen and (min-width: 106rem) {
  .slider-top-content {
    width: 30%;
    left: 5%;
  }
}

/* Zusätzlicher Viewport 1800 */
@media screen and (min-width: 112.5rem) {
  .slider-top-content {
    width: 30%;
    left: 8%;
  }
}

/* Zusätzlicher Viewport 2000 */
@media screen and (min-width: 125rem) {
  .slider-top-content {
    width: 30%;
    left: 12%;
  }
}

/* Zusätzlicher Viewport 2100 */
@media screen and (min-width: 131.25rem) {
  .slider-top-content {
    width: 30%;
    left: 14%;
  }
}

/* Zusätzlicher Viewport 2200 */
@media screen and (min-width: 137.5rem) {
  .slider-top-content {
    width: 25%;
    left: 16%;
  }
}

/* Zusätzlicher Viewport 2300 */
@media screen and (min-width: 143.75rem) {
  .slider-top-content {
    width: 25%;
    left: 17%;
  }
}

/* Zusätzlicher Viewport 2560 */
@media screen and (min-width: 160rem) {
  .slider-top-content {
    width: 20%;
    left: 20.5%;
  }
}
