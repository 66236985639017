#myBtn {
  display: none;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 99;
  border: none;
  outline: none;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

#myBtn:hover {
  background-color: var(--color-primary-hover);
}
