/* allgemeine Infos und viewport-sx 320px */
.startseitenbox {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: calc(var(--gutter)*2);
  padding-bottom: calc(var(--gutter)*2);
  border-bottom: 3px solid var(--color-grey-light);
}

.startseitenbox:first-of-type {
  margin-top: 0;
}

.startseitenbox:last-of-type {
  padding-bottom: 0;
  border-bottom: 0;
}

.iframe-container iframe {
  display: block;
  width: 288px;
  height: 162px;
  margin-left: auto;
  margin-right: auto;
}

.startseitenbox h3 {
  width: 100%;
  text-align: center;
  color: var(--font-color);
}

.startseitenbox a:hover h3 {
  color: var(--color-primary-hover);
}

.startseitenbox p {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}

.startseitenbox img {
  margin-top: var(--gutter-horizontal);
  margin-bottom: var(--gutter-horizontal);
}


/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .iframe-container iframe {
    display: block;
    width: 536px;
    height: 335px;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .startboxen-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
  }

  .startseitenbox {
    width: 33.33%;
    padding-left: var(--gutter);
    padding-right: var(--gutter);
    padding-bottom: 0;
    margin-top: 0;
    border-bottom: 0;
    border-right: 3px solid var(--color-grey-light);
  }

  .startseitenbox:last-of-type {
    border-right: 0;
  }

  .iframe-container iframe {
    width: 213px;
    height: 119px;
  }

  .startseitenbox p,
  .startseitenbox a.btn-primary {
    text-align: center;
  }

  .startseitenbox a.btn-primary {
    margin-top: auto;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .iframe-container iframe {
    width: 288px;
    height: 162px;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .startseitenbox {
    padding-left: var(--gutter-horizontal);
    padding-right: var(--gutter-horizontal);
  }

  .iframe-container iframe {
    width: 315px;
    height: 197px;
  }

  .startseitenbox img {
    margin-top: calc(var(--gutter)*2);
    margin-bottom: calc(var(--gutter)*2.5);
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .startseitenbox {
    padding-left: calc(var(--gutter)*2);
    padding-right: calc(var(--gutter)*2);
  }

  .iframe-container iframe {
    width: 442px;
    height: 248px;
  }

  .startseitenbox img {
    margin-top: calc(var(--gutter)*3);
    margin-bottom: calc(var(--gutter)*3.5);
  }
}
