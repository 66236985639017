/*
 * Filename: base.css
 */

* {
  position: relative;

  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
}

table {
  width: 100%;
  margin-bottom: var(--gutter);
}

th,
td {
  padding: .2rem;

  border-bottom: 1px solid var(--light-grey);
}

code {
  display: inline-block;

  padding: 0 .2em;

  color: var(--code);
  background: #eee;
}

/** Firefox Glow bei invaliden Formularfeldern entfernen */
:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}
