/* allgemeine Infos und viewport-sx 320px */
.leistungen-wrapper {
  background-image: url('img/mauer.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

.leistungsbox {
  margin-top: calc(var(--gutter)*2.5);
}

.leistungsbox a {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.leistungsbox-img {
  display: flex;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  align-self: center;
  border-radius: 2.5rem;
  background-color: var(--color-primary);
  margin-bottom: calc(var(--gutter)*0.8);
}

.leistungsbox a:hover .leistungsbox-img {
  background-color: var(--color-primary-hover);
}

.leistungsbox-img img {
  width: 3rem;
  height: 100%;
}

.leistungsbox h4 {
  width: 100%;
  color: var(--font-color);
  font-size: var(--font-size-h5);
  margin-bottom: 0;
  text-align: center;
}

.leistungsbox a:hover h4 {
  color: var(--color-primary-hover);
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .leistungsbox-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: var(--gutter-horizontal);
  }

  .leistungsbox {
    width: 48%;
    margin-right: 4%;
  }

  .leistungsbox:nth-of-type(even) {
    margin-right: 0;
  }

  .leistungsbox:nth-of-type(1),
  .leistungsbox:nth-of-type(2) {
    margin-top: 0;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .leistungsbox-wrapper {
    margin-top: 0;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .leistungen-wrapper {
    padding-top: calc(var(--gutter)*2);
    padding-bottom: calc(var(--gutter)*2);
  }

  .leistungsbox {
    width: 32%;
    margin-right: 2%;
  }

  .leistungsbox:nth-of-type(even) {
    margin-right: 2%;
  }

  .leistungsbox:nth-of-type(3n) {
    margin-right: 0;
  }

  .leistungsbox:nth-of-type(1),
  .leistungsbox:nth-of-type(2),
  .leistungsbox:nth-of-type(3) {
    margin-top: 0;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .leistungsbox-wrapper {
    position: relative;
    padding-left: calc(var(--gutter)*8);
  }

  .leistungsbox-wrapper::before {
    position: absolute;
    content: url('img/pfeil.svg');
    width: 5rem;
    height: 100%;
    left: 5%;
    top: 50%;
    transform: translateX(-50%);
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .leistungsbox-wrapper::before {
    position: absolute;
    content: url('img/pfeil.svg');
    width: 5rem;
    height: 100%;
    left: 0;
    top: 0;
    transform: none;
  }

  .leistungsbox-wrapper {
    margin-top: calc(var(--gutter)*6);
  }

  .leistungsbox {
    width: 23.5%;
    margin-right: 2%;
  }

  .leistungsbox:nth-of-type(even) {
    margin-right: 2%;
  }

  .leistungsbox:nth-of-type(3n) {
    margin-right: 2%;
  }

  .leistungsbox:nth-of-type(4n) {
    margin-right: 0;
  }

  .leistungsbox:nth-of-type(1),
  .leistungsbox:nth-of-type(2),
  .leistungsbox:nth-of-type(3),
  .leistungsbox:nth-of-type(4) {
    margin-top: 0;
  }
}
