/*
 * Filename: helpers.css
 *
 * Helper classes
 */

.visible,
.visible-xs {
  display: initial !important;
}

.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
  display: none !important;
}

.hidden-xs {
  display: none !important;
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

.color-grey-light {
  color: var(--color-grey-light);
}

.color-grey-dark {
  color: var(--color-grey-dark);
}

.color-error {
  color: var(--error);
}

.color-success {
  color: var(--success);
}

.text-handwritten {
  font-family:'Rage Italic W01';
  font-weight: normal;
  font-size: var(--font-size-h4);
}

.text-upper {
  text-transform: uppercase;
}

.text-bold {
  font-family:'Futura LT W01 Bold';
  font-weight: normal;
}

button.btn-primary,
.btn-primary {
  display: block;
  text-transform: uppercase;
  color: white;
  padding: calc(var(--gutter)*0.5) var(--gutter);
  background-color: var(--color-primary);
  border: 0;
}

button.btn-primary:hover,
.btn-primary:hover {
  color: white;
  background-color: var(--color-primary-hover);
}

button.btn-secondary,
.btn-secondary {
  display: block;
  text-transform: uppercase;
  color: white;
  padding: calc(var(--gutter)*0.3) calc(var(--gutter)*0.5);
  background-color: var(--color-secondary);
  border: 0;
}

button.btn-secondary:hover,
.btn-secondary:hover {
  color: white;
  background-color: var(--color-secondary-hover);
}

.error {
  color: var(--error)
}

.border-error {
  border: 1px solid var(--error);
}

.success {
  color: var(--success)
}

@media screen and (--viewport-sm) {
  .visible-xs,
  .visible-md,
  .visible-lg,
  .visible-xl {
    display: none !important;
  }

  .visible-sm {
    display: initial !important;
  }

  .hidden-xs {
    display: initial !important;
  }

  .hidden-sm {
    display: none !important;
  }
}

@media screen and (--viewport-md) {
  .visible-xs,
  .visible-sm,
  .visible-lg,
  .visible-xl {
    display: none !important;
  }

  .visible-md {
    display: initial !important;
  }

  .hidden-sm {
    display: initial !important;
  }

  .hidden-md {
    display: none !important;
  }
}

@media screen and (--viewport-lg) {
  .visible-xs,
  .visible-sm,
  .visible-md,
  .visible-xl {
    display: none !important;
  }

  .visible-lg {
    display: initial !important;
  }

  .hidden-md {
    display: initial !important;
  }

  .hidden-lg {
    display: none !important;
  }

  button.btn-primary,
  .btn-primary,
  button.btn-secondary,
  .btn-secondary  {
    padding: calc(var(--gutter)*0.5) var(--gutter);

  }
}

@media screen and (--viewport-xl) {
  .visible-xs,
  .visible-sm,
  .visible-md,
  .visible-lg {
    display: none !important;
  }

  .visible-xl {
    display: initial !important;
  }

  .hidden-lg {
    display: initial !important;
  }

  .hidden-xl {
    display: none !important;
  }
}
