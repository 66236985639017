/* allgemeine Infos und viewport-sx 320px */

.leistungen-wrapper,
.startboxen-wrapper,
.meinungen-wrapper,
.kontakt-wrapper,
.referenz-wrapper,
.content-wrapper article {
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
}

.content-wrapper ul {
  list-style-type: none;
  padding-left: 0;
}

.content-wrapper li {
  padding-left: 1rem;
  text-indent: -0.9rem;
  margin-bottom: calc(var(--gutter)*0.9);
}

.content-wrapper li:last-of-type {
  margin-bottom: 0;
}

.content-wrapper li::before {
  content: '•';
  color: var(--color-primary);
  margin-right: 0.3rem;
}

/* Layouts */
.content-left img,
.content-right img,
.content-above img,
.content-below img {
  margin-bottom: 1rem;
}

.content-right a:last-of-type img,
.content-below a:last-of-type img {
  margin-bottom: 0;
}

.layout-1  a.oneImg,
.layout-2  a.oneImg,
.layout-3  a.oneImg,
.layout-4  a.oneImg {
  width: 100%;
  margin-right: 0;
}

.content-wrapper .content-right p:last-of-type,
.content-wrapper .content-below p:last-of-type {
  margin-bottom: 0;
}

.fileupload-layout {
  margin-top: var(--gutter);
}

.fileupload-layout a img,
.fileupload-layout a img,
.fileupload-layout a img,
.fileupload-layout a img {
  width: 1rem;
  height: 100%;
  margin-right: calc(var(--gutter)*0.3);
  margin-bottom: 0
}

.layout-4 .content-above a,
.layout-2 .content-left a,
.layout-1 .content-right a,
.layout-3 .content-below a {
  color: var(--color-primary);
}

.layout-4 .content-above a:hover,
.layout-2 .content-left a:hover,
.layout-1 .content-right a:hover,
.layout-3 .content-below a:hover {
  color: var(--color-primary-hover);
}


/* Bildnachweis Impressum */
.bildnachweis {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.bildnachweis img {
  background-color: var(--color-grey-light);
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  /* Layouts */
  .layout-4 .content-below,
  .layout-1 .content-left,
  .layout-2 .content-right,
  .layout-3 .content-above {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .layout-4 .content-below a,
  .layout-2 .content-right a,
  .layout-1 .content-left a,
  .layout-3 .content-above a {
    width: 32%;
    margin-right: 2%;
  }

  .layout-4 .content-below a:nth-of-type(3n),
  .layout-1 .content-left a:nth-of-type(3n),
  .layout-2 .content-right a:nth-of-type(3n),
  .layout-3 .content-above a:nth-of-type(3n) {
    margin-right: 0;
  }

  .layout-4  a.oneImg,
  .layout-1  a.oneImg,
  .layout-2  a.oneImg,
  .layout-3  a.oneImg {
    width: 100%;
    margin-right: 0;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .leistungen-wrapper,
  .startboxen-wrapper,
  .meinungen-wrapper,
  .kontakt-wrapper,
  .referenz-wrapper,
  .content-wrapper article {
    padding-top: calc(var(--gutter)*2);
    padding-bottom: calc(var(--gutter)*2);
  }

  /* Layouts */
  .layout-2 .content-right a,
  .layout-1 .content-left a {
    width: 100%;
    margin-right: 0;
  }

  .layout-1 .content-left a:nth-of-type(2n),
  .layout-2 .content-right a:nth-of-type(2n) {
    margin-right: 0;
  }

  .layout-2 .content-left {
    margin-right: calc(var(--gutter)*2);
  }

  .layout-1 .content-right {
    margin-left: calc(var(--gutter)*2);
  }

  .layout-1  a.oneImg img,
  .layout-2  a.oneImg img {
    margin-bottom: 0;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .leistungen-wrapper,
  .startboxen-wrapper,
  .meinungen-wrapper,
  .kontakt-wrapper,
  .referenz-wrapper,
  .content-wrapper article {
    padding-top: calc(var(--gutter)*3);
    padding-bottom: calc(var(--gutter)*3);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  /* Layouts */
  .layout-4 .content-below a,
  .layout-3 .content-above a {
    width: 23.5%;
    margin-right: 2%;
  }

  .layout-4 .content-below a:nth-of-type(3n),
  .layout-3 .content-above a:nth-of-type(3n) {
    margin-right: 2%;
  }

  .layout-4 .content-below a:nth-of-type(4n),
  .layout-3 .content-above a:nth-of-type(4n) {
    margin-right: 0;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .leistungen-wrapper,
  .startboxen-wrapper,
  .meinungen-wrapper,
  .kontakt-wrapper,
  .referenz-wrapper,
  .content-wrapper article {
    padding-top: calc(var(--gutter)*4);
    padding-bottom: calc(var(--gutter)*4);
  }
}
