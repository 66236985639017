/* allgemeine Infos und viewport-sx 320px */
/* Anfrage Startseite */
#contactAnfrage,
#contactBottem {
  margin-left: -7px;
}

.anfrage-footer-wrapper {
  padding-top: var(--gutter-horizontal);
  background-color: var(--color-primary);
}

.anfrage-footer-wrapper h3 {
  color: white;
}

.anfrage-footer-wrapper .info-datencheck,
.anfrage-footer-wrapper .info-datencheck a {
  color: white;
}

.anfrage-footer-wrapper .info-datencheck a {
  font-size: var(--font-size-small);
}

.anfrage-footer-wrapper .info-datencheck a:hover {
  color: var(--color-secondary);
}

.anfrage-footer-wrapper .anfrage-footer-telefon h3 {
  font-size: var(--font-size-h1);
  text-align: center;
  margin-top: calc(var(--gutter)*2);
}

.anfrage-footer-telefon a {
  display: table;
  font-size: var(--font-size-h3);
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--gutter-horizontal);
}

.anfrage-footer-telefon p {
  font-size: var(--font-size-h5);
  line-height: 1.2;
  text-align: center;
  color: white;
  margin-bottom: calc(var(--gutter)*3);
}
.anfrage-footer-content #contactAnfrage p {
  margin-bottom: 0.5rem;
  text-align: left;
}
.anfrage-footer-wrapper img {
  display: block;
  width: 100%;
  height: auto;
}

#contactAnfrage button {
  cursor: pointer;
}

/* Kontaktformular */
#contactBottem button {
  margin-right: 0;
  margin-left: auto;
}

#contact-hinweis {
  text-align: right;
  margin-top: var(--gutter);
}

.pure-checkbox a {
  font-size: 0.9rem;
  color: var(--color-primary);
}

.pure-checkbox a:hover {
  color: var(--color-primary-hover);
}

/* Maps */
.maps-container {
  margin-top: var(--gutter-horizontal);
  width: 100%;
  height: 15rem;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  #contactAnfrage,
  #contactBottem {
    margin-left: 0;
  }

  .anfrage-footer-wrapper img {
    display: block;
    width: 20rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  /* Maps */
  .maps-container {
    margin-top: calc(var(--gutter)*3);
    height: 12rem;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .anfrage-footer-wrapper {
    background-image: url('img/parallax.jpg');
    background-attachment: fixed;
    background-position: top right;
    background-size: cover;
    padding-top: calc(var(--gutter)*2);
  }

  #contactAnfrage textarea {
    height: 8.3rem;
  }

  .anfrage-footer-wrapper .anfrage-footer-telefon {
    padding-left: calc(var(--gutter)*2);
  }

  .anfrage-footer-wrapper .anfrage-footer-telefon h3 {
    margin-top: 0;
  }

  /* Maps */
  .maps-container {
    height: 15rem;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .anfrage-footer-wrapper {
    padding-top: calc(var(--gutter)*3);
  }

  .anfrage-footer-wrapper img {
    width: 15rem;
  }

  /* Maps */
  .maps-container {
    margin-top: calc(var(--gutter)*4);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .anfrage-footer-wrapper {
    padding-top: calc(var(--gutter)*4);
  }

  .anfrage-footer-content {
    padding-left: calc(var(--gutter)*8);
    padding-right: calc(var(--gutter)*8);
  }

  /* Maps */
  .maps-container {
    height: 20rem;
  }
}
