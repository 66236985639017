/* allgemeine Infos und viewport-sx 320px */
footer {
  padding-bottom: calc(var(--gutter)*2.5);
}

.main-footer,
.subfooter {
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
}

.main-footer {
  background-color: var(--color-grey-dark);
}

.main-footer-content {
  margin-top: var(--gutter-horizontal);
}

.main-footer-content:first-of-type {
  margin-top: 0;
}

.main-footer-content h6 {
  font-size: var(--font-size);
  text-transform: uppercase;
  color: var(--color-primary);
  margin-bottom: calc(var(--gutter)*0.8);
}

.main-footer-content p {
  line-height: 1.5;
}

.main-footer-content:first-of-type p:first-of-type {
  margin-bottom: calc(var(--gutter)*0.8);
}

.main-footer-content:first-of-type p:last-of-type {
  margin-bottom: 0;
}

.main-footer-content ul {
  list-style-type: none;
  padding-left: 0;
}

.main-footer-content li {
  border-bottom: 1px solid var(--font-color);
  padding-bottom: calc(var(--gutter)*0.5);
  margin-bottom: calc(var(--gutter)*0.5);
}

.main-footer-content li:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.main-footer-content li a {
  display: block;
  color: var(--font-color);
}

.main-footer-content li a:hover {
  color: var(--color-primary);
}

.footer-kontakt {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: var(--gutter);
}

.footer-kontakt:first-of-type {
  margin-top: 0;
}

.footer-kontakt img {
  width: 1.5rem;
  height: 100%;
  padding-top: calc(var(--gutter)*0.2);
  margin-right: calc(var(--gutter)*0.9);
}

.footer-kontakt p {
  width: 80%;
  margin-bottom: 0;
}

.footer-kontakt a {
  color: var(--font-color);
}

.footer-kontakt:last-of-type a {
  color: var(--color-primary);
}

/* Subfooter */
.subfooter-content p,
.subfooter-content a {
  font-family:'Futura LT W01 Light';
  font-weight: normal;
}

.subfooter-content ul {
  list-style-type: none;
  padding-left: 0;
}

.subfooter-content li {
  text-align: center;
  margin-bottom: calc(var(--gutter)*0.5);
}

.subfooter-content li:last-of-type {
  margin-bottom: 0;
}

.subfooter-content a,
.subfooter-content li a {
  color: var(--font-color);
}

.subfooter-content a:hover,
.subfooter-content li a:hover {
  color: var(--color-primary);
}

.subfooter-content p {
  text-align: center;
}

.subfooter-content p:last-of-type {
  margin-bottom: 0;
}


/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .main-footer-inner-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .main-footer-content:first-of-type {
    order: 1;
    width: 47%;
    margin-right: 6%;
  }

  .main-footer-content:last-of-type {
    order: 2;
    width: 47%;
    margin-right: 0;
    margin-top: 0;
  }

  .main-footer-content:nth-of-type(2) {
    order: 3;
    width: 100%;
  }

  .main-footer-content ul {
    column-count: 2;
    column-gap: 2rem;
  }

  /* Subfooter */
  .subfooter-content ul {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  .subfooter-content li {
    margin-right: var(--gutter);
    margin-bottom: 0;
  }

  .subfooter-content li:last-of-type {
    margin-right: 0;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .main-footer,
  .subfooter {
    padding-top: calc(var(--gutter)*2);
    padding-bottom: calc(var(--gutter)*2);
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  footer {
    padding-bottom: 0;
  }

  .main-footer,
  .subfooter {
    padding-top: calc(var(--gutter)*3);
    padding-bottom: calc(var(--gutter)*3);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .main-footer-content:first-of-type {
    order: 1;
    width: 22%;
    margin-right: 6%;
  }

  .main-footer-content:last-of-type {
    order: 3;
    width: 22%;
    margin-right: 0;
  }

  .main-footer-content:nth-of-type(2) {
    order: 2;
    width: 44%;
    margin-right: 6%;
    margin-top: 0;
  }

  .subfooter-content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }

  .subfooter-content p:first-of-type {
    width: 27%;
  }

  .subfooter-content ul {
    width: 54%;
    justify-content: flex-start;
  }

  .subfooter-content p:nth-of-type(2),
  .subfooter-content p:nth-of-type(3) {
    width: 100%;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .main-footer,
  .subfooter {
    padding-top: calc(var(--gutter)*4);
    padding-bottom: calc(var(--gutter)*4);
  }

  .subfooter-content p:first-of-type {
    width: 21%;
  }

  .subfooter-content ul {
    width: 43%;
    justify-content: flex-start;
  }
}
